import { FC, lazy, LazyExoticComponent } from "react"

/** Маршруты навигации */
const routePaths = {
  HOME: "/",
  DASHBOARD: "/dashboard",
  EQUIPMENTS: "/equipments",
  MODULES: "/modules",
  MODULES_ADDING: "/modules/adding",
  NOTIFICATIONS: "/notifications",
  REPORTS: "/reports",
  DOWNLOADS: "/downloads",
  SETTINGS: "/settings",
  MODES: "/modes",
}

// Todo LIB: Вынести в библиотеку
type TPrivateRoutes = {
  path: string
  element: LazyExoticComponent<FC>
}[]

/** Массив страниц, для Routes */
const privateRoutes: TPrivateRoutes = [
  {
    path: routePaths.DASHBOARD,
    element: lazy(() => import("../pages/DashboardPage")),
  },
  {
    path: routePaths.EQUIPMENTS,
    element: lazy(() => import("../pages/EquipmentsPage")),
  },
  {
    path: `${routePaths.EQUIPMENTS}/:id`,
    element: lazy(() => import("../pages/EquipmentPage")),
  },
  {
    path: routePaths.MODULES,
    element: lazy(() => import("../pages/EchoModulesPage")),
  },
  {
    path: `${routePaths.MODULES}/:id`,
    element: lazy(() => import("../pages/EchoModulePage")),
  },
  {
    path: routePaths.MODULES_ADDING,
    element: lazy(() => import("../pages/AddingEchoModulePage")),
  },
  {
    path: routePaths.NOTIFICATIONS,
    element: lazy(() => import("../pages/NotificationsPage")),
  },
  {
    path: routePaths.REPORTS,
    element: lazy(() => import("../pages/ReportsPage")),
  },
  {
    path: routePaths.DOWNLOADS,
    element: lazy(() => import("../pages/DownloadsPage")),
  },
  {
    path: routePaths.SETTINGS,
    element: lazy(() => import("../pages/SettingsPage")),
  },
  {
    path: routePaths.MODES,
    element: lazy(() => import("../pages/ModesPage")),
  },
]

export { privateRoutes, routePaths }
