import React, { createContext, FC, useMemo, useContext, useState } from "react"
import { RU, EN, getLangFromCookie, EShortLanguageNames, ILocale } from "shared"

interface ILocalizationContextProvider {
  children: React.ReactNode
}

interface ILocalizationContext {
  l: ILocale
  localeState: {
    locale: EShortLanguageNames
    setLocale: React.Dispatch<React.SetStateAction<EShortLanguageNames>>
  }
}

const LocalizationContext = createContext({} as ILocalizationContext)

export function useLocalizationContext(): ILocalizationContext {
  return useContext(LocalizationContext)
}

export const LocalizationContextProvider: FC<ILocalizationContextProvider> = ({ children }): JSX.Element => {
  const [locale, setLocale] = useState<EShortLanguageNames>(getLangFromCookie)

  const l = useMemo(() => EN, [locale]) as ILocale

  const providerValue = useMemo(
    () => ({
      l,
      localeState: { locale, setLocale },
    }),
    [locale, l]
  )

  return <LocalizationContext.Provider value={providerValue}>{children}</LocalizationContext.Provider>
}
