import React, { FC, createContext, useContext, useMemo, useEffect } from "react"
import { EchoWSController } from "shared"

import { useTypedSelectorHook } from "@utils"

interface IWSContext {
  ws: EchoWSController
}

interface IWSContextProvider {
  children: JSX.Element
}

const WSContext = createContext<IWSContext>({} as IWSContext)

function useWSContext(): IWSContext {
  return useContext(WSContext)
}

const WSContextProvider: FC<IWSContextProvider> = ({ children }) => {
  const { isAuth } = useTypedSelectorHook(({ main }) => main)

  const ws = useMemo(() => new EchoWSController(), [])

  useEffect(() => {
    if (!isAuth) {
      ws.disconnect()

      return
    }

    ws.connect()

    return () => {
      ws.disconnect()
    }
  }, [isAuth])

  return <WSContext.Provider value={{ ws }}>{children}</WSContext.Provider>
}

export { WSContextProvider, useWSContext }
